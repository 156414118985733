@import 'var';

.slick-arrow {
    height: 50px;
    width: 50px;
    z-index: 100;

    &:before {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        font-size: 20px;
        line-height: 1;
        opacity: .75;
        color: $primary;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 50px;
    }
}

.slick-prev {
    left: 0;
    top: 124%;
    background: rgba($primary, 18%);

    &:before {
        content: '\e408';
    }
}

.slick-next {
    right: 0;
    top: 124%;
    background: rgba($primary, 18%);

    &:before {
        content: '\e409';
    }
}

.slick-list {
    margin: 0 -15px;
    padding: 0.1em 0;
}

.slick-slide>div {
    padding: 0 15px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .slick-prev {
        top: 50%;
        background: none;
        left: -50px;
    }

    .slick-next {
        top: 50%;
        background: none;
        right: -50px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}